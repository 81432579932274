@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Work Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: grid;
  text-align: center;
  background: linear-gradient(200deg, #fbffff, #beffff);
  justify-content: center;
  align-items: center;
}

.App-content {
  display: flex;
  flex-direction: row;
  grid-gap: 3em;
  gap: 3em;
  justify-content: center;
  align-items: center;

}





.logos {
  display: flex;
  flex-direction: column;
}

.home-logo {
  transition: all 1s ease-in-out;
}

.home-logo:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 1s ease-in-out;
}

.home-logotipo {
  max-width: 380px;
  pointer-events: none;
}

.tranki {
  text-decoration: underline;
  font-style: italic;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0e0e0e;
}

.App-link {
  color: #40477E;
}

.reloj {
  font-weight: 800;
}

.direccion {
  text-decoration: none;
  color: #0e0e0e;
  transition: all .5s;
}

.direccion:hover {
  color: #40477E;
  font-size: 1.03em;
  transition: all .5s;
}
.whatsAppIcon{
  display: inline-block;
  position: relative;
  fill: #0aa142;
  height: 1.2em;
  width: 1.2em;
  top:0.25em;
  margin-left: .3em;
}
.pWhps{
  padding-bottom: .3em;
}

.link-contacto {
  text-decoration: none;
  font-weight: 800;
  color: #40477E;
  transition: all 1s;
  pointer-events: fill;
}

.link-contacto:hover {
  color: #BE0511;
  font-size: 1.1em;
  transition: all 1s;
}

.whatsapp:hover{
  color: #0aa142;
}

.inf-esp {
  padding-top: 0.5em;
}

p {
  -webkit-margin-before: 0.5em !important;
          margin-block-start: 0.5em !important;
  -webkit-margin-after: 0.5em !important;
          margin-block-end: 0.5em !important;
}


.social-links a i{
  font-size: 2em;
  margin: 0.3em .5em;
  color:#40477E;
  transition: all .5s;
  cursor: pointer;
}

.social-links a i:hover{
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: all .5s;
  color:#6c5399;
}





.formButton {
  margin: 0em 0.5em;
  border-radius: 30px;
  border: none;
  padding: 0.5em 0.8em;
  background-color: transparent;
  font-family: inherit;
  font-size: 1em;
  font-weight: 600;
  color: #40477E;
  transition: all 2s;
  pointer-events: fill;
}

.formButton:hover {
  font-family: inherit;
  font-size: 1em;
  font-weight: 600;
  background-color: #fff;
  /* color:#fff;
  background-color: #40477E; */
  transition: all .5s;
  pointer-events: visible;
  box-shadow: 0px 3px 10px -2px #10abff;
}




/* Form transition */

.form-enter {
  opacity: 0;
  transform: scale(0.9);
  -webkit-transform: perspective(1000px) rotateY(20deg);
  transform: perspective(1000px) rotateY(20deg);
}

.form-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 700ms, -webkit-transform 700ms;
  transition: opacity 700ms, transform 700ms;
  transition: opacity 700ms, transform 700ms, -webkit-transform 700ms;
}





/* Contact Form */


.form-component {
  display: flex;
  height: auto;
  max-width: 350px;
  flex-direction: column;
  text-align: start !important;
  /* border: solid 1pt red; */
  box-shadow: 0px 2px 15px 3px rgba(64, 71, 126, 0.3);
  border-radius: 15px;
  padding: 3em 4.5em 3em 3em;
  margin: 1em;
  justify-content: center;
}

.form-component p {
  text-align: start;
}

.textForm{
  margin-bottom: .5em;
}

.valError {
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(211, 17, 17);
  margin: 0 0 0 1em;
  position: relative;
  top: -0.1em;

}


.form-component label{
  font-size: 0.85em;
}
.form-component input,
textarea {
  font-family: inherit;
  font-size: 0.8em;
  width: 100%;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  padding: 0.5em;
  border-radius: 7px;
  border: 1pt solid #40477E;
  transition: all 1s ease-in-out;
  resize: vertical;

}

.input-looks1:hover {
  transition: all 2s ease-in-out;
  box-shadow: 0px 3px 15px -2px #65c9ff;
}

.input-looks1:focus {
  font-family: inherit;
  border: 1pt solid #40477E !important;
  outline: #40477E;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 15px -2px #65c9ff;
}

option {
  font-family: inherit !important;
}

em {
  font-weight: 700;
}

.pre-coso-code {
  text-align: start;
  color: white;
  background-color: #0e0e0e;
  border-radius: 15px;
  padding-left: 1em;
}

.descrip-input {
  min-height: 100px;
  transition: 0.1s !important;
}

.cont-send {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.sending{
  position: absolute;
  right: 1.2em;
  display: inherit;
  margin-right: .5em;
  padding: .2em .2em;
  border-radius: 50px;
  color:red;
}

.sendingRing{
  
  opacity: 1;
  transition: all 1s;
}

.close-icon {
  position: relative;
  width: 1.5em;
  left: 1.5em;
  opacity: 0.5;
}



/* Botones */


.boton-gen {
  position: relative;
  left:.8em;
  font-family: inherit;
  font-weight: 500;
  font-size: 1em;
  padding: 0.9em 1em;
  border-radius: 30px;
  color: #beffff;
  background-color: #40477E;
  border: 1pt solid #fff;
  transition: all 2s ease-in-out;
}

.boton-gen:hover {
  color: #fff;
  background-color: #6c5399;
  border: 1pt solid #beffff;
  transition: all .5s ease-out;
  box-shadow: 0px 3px 10px -2px #10abff;
}


/* Media Queries */

@media screen and (max-width:880px) {

  .App-content {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
    justify-content: center;
    align-items: center;

  }

  .App-header {
    position: relative;
    top: -2em;
    margin: 0;
    justify-content: center;
  }

  .contact-form-div {
    position: relative;
    top: -2em;
    margin-bottom: 4em;
  }

}

@media screen and (max-width:400px) {

  .App {
    font-size: 0.9em;
    width: 100vw;
  }

  .App-header {
    max-width: 300px;
  }

  .logos {
    width: 80%;
  }

  
.form-enter {
  opacity: 0;
  transform: scale(0.9);
  -webkit-transform: perspective(1000px) rotateX(20deg);
  transform: perspective(1000px) rotateX(20deg);
}
.form-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 700ms, -webkit-transform 700ms;
  transition: opacity 700ms, transform 700ms;
  transition: opacity 700ms, transform 700ms, -webkit-transform 700ms;
}
}
